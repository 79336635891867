.monitor-container {
    font-family: sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 11px;
    margin: 0px;
    padding: 20px;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    text-align: center;
    background-color: #e8e9eb;
    overflow: scroll;
    width: 100%;
}

.concept-container {
    display: block;
    max-width: 550px;
    width: 100%;
    margin: 0px auto;
    background-color: #fffcfc;
    border-radius: 30px;
    box-sizing: border-box;
    margin-bottom: 20px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    overflow: hidden;
}

.table-container {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
    margin-bottom: 20px;
}

th,
td {
    padding: 5px;
    text-align: center;
    font-weight: bold;
    border-right: 1px solid #ccc;
    overflow: hidden;
    width: 10%;
}

td:first-child {
    width: 50%;
}

th {
    background-color: #ffffff;
    writing-mode: vertical-lr;
    text-orientation: mixed;
    padding-block: 5px;
    text-align: center;
}

d:first-child,
th:first-child {
    border-left: none;
}

td:last-child,
th:last-child {
    border-right: none;
}

tr {
    border-bottom: 1px solid #ccc;
}

.concept-pill {
    display: inline-block;
    background-color: #fefcfc;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.3);
    border-radius: 30px;
    padding: 10px 10px;
    margin: 5px;
    text-align: center;
    font-weight: bold;
    font-size: large;
    margin-bottom: 20px;
    padding-bottom: 10px;
}
